import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Element } from "react-scroll";
import Footer from "../components/Footer/Footer";
import About from "../components/home/aboutUs/About";
import Clients from "../components/home/clients/Clients";
import Gallery from "../components/home/gallery/Gallery";
import Header from "../components/home/header/Header";
import NavbarComponent from "../components/home/navbar/NavbarComponent";
import Speak from "../components/speak/Speak";

const HomePage = ({ setContactModal2 }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <Element id="home">
      <NavbarComponent setContactModal2={setContactModal2} />
      <Header />
      <About />
      <Clients />
      <Speak />
      <Gallery />
      <Footer />
    </Element>
  );
};

export default HomePage;
