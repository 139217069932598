import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import DoorsIMG from "../../assets/imgs/Why Do You Need Us 2.jpg";
import interior from "../../assets/imgs/interior Design/IMG-20191230-WA0055.png";
import DressingIMG from "../../assets/imgs/Dressing Room/IMG_8295.png";
import KitchensIMG from "../../assets/imgs/Kitchens/IMG_8385.png";
import classes from "./gallary.module.css";
import { useState } from "react";
import Doors from "../doors/Doors";
import Interior from "../interior/Interior";
import Dressing from "../Dressing/Dressing";
import Kitchens from "../Kitchens/Kitchens";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const Gallary = () => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();

  const doorCheck = id === "1";
  const interiorCheck = id === "2";
  const DressingCheck = id === "3";
  const KitchensCheck = id === "4";

  return (
    <div className={classes.galleryContainer}>
      <h2> {t("gallary")}</h2>
      <Container fluid>
        <Row>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <Link to="/gallery/1">
                <img
                  src={DoorsIMG}
                  alt=""
                  className={id === "1" && classes.active}
                />
              </Link>
              <h2>{t("gallery-h1")}</h2>
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <Link to="/gallery/2">
                <img
                  src={interior}
                  alt=""
                  className={id === "2" && classes.active}
                />
              </Link>
              <h2>{t("gallery-h2")}</h2>
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <Link to="/gallery/3">
                <img
                  src={DressingIMG}
                  alt=""
                  className={id === "3" && classes.active}
                />
              </Link>
              <h2>{t("gallery-h3")}</h2>
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <Link to="/gallery/4">
                <img
                  src={KitchensIMG}
                  alt=""
                  className={id === "4" && classes.active}
                />
              </Link>
              <h2>{t("gallery-h4")}</h2>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Display Images */}
      <div className={classes.allImgs}>
        {doorCheck && <Doors />}
        {interiorCheck && <Interior />}
        {DressingCheck && <Dressing />}
        {KitchensCheck && <Kitchens />}
      </div>
    </div>
  );
};
export default Gallary;
