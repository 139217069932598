import React from "react";
import classes from "./about.module.css";
import imgg from "../../../assets/imgs/Dental cabinet bg.png";
import { useTranslation } from "react-i18next";

const About = () => {
  const [t, i18n] = useTranslation();

  return (
    <div className={classes.containerAbout}>
      <div className={classes.aboutContianer}>
        <h1>{t("about-us2")}</h1>
        <ul className={classes.aboutUl}>
          <li>{t("aboutLI_1")}</li>
          <li>{t("aboutLI_2")}</li>
          <li>{t("aboutLI_3")}</li>
          <li>{t("aboutLI_4")}</li>
          <li>{t("aboutLI_5")}</li>
          <li>{t("aboutLI_6")}</li>
        </ul>
      </div>
    </div>
  );
};

export default About;
