import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import classes from "./ContactUs.module.css";

import icon1 from "../../assets/imgs/Icons/Asset 5.png";
import icon2 from "../../assets/imgs/Icons/Asset 122.png";
import icon3 from "../../assets/imgs/Icons/Asset 11.png";

import whatsApp from "../../assets/imgs/Icons/Asset 6.png";
import { useTranslation } from "react-i18next";

import toast from "react-hot-toast";

const ContactUs = ({
  contactModal,
  setContactModal,
  setContactModal2,
  contactModal2,
}) => {
  const [t] = useTranslation();
  const [name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [mobile, setMobile] = useState(0);
  const [message, setMessage] = useState("");

  const sendEmail = async (ev) => {
    ev.preventDefault();
    const formData = new FormData();
    const url = new URL("https://py.dws.teqneia.com/contacts/?tenant_id=7");
    // e.preventDefault();
    try {
      const ipResp = await fetch("https://api.ipify.org?format=json");
      const data = ipResp.json();
      const ip = data.ip;

      formData.append("name", name);
      formData.append("phone", mobile);
      formData.append("email", Email);
      formData.append("message_body", message);
      formData.append("company_name", "");
      formData.append("ip", ip);

      if (!name || !Email || !message) {
        toast.error("Please Field All Inputs !");
        return;
      }

      const response = await fetch(url, { method: "POST", body: formData });
      if (!response.ok) {
        throw new Error("Unable to send.");
      }
      toast.success("Mail sent successfully.");
      setContactModal(false);
      setContactModal2(false);
    } catch (error) {
      toast.error("Unable to send.");
    }

    // emailjs
    //   .sendForm(
    //     "service_pmij3ea",
    //     "template_aoprdir",
    //     e.target,
    //     "i_aocxF5NNj_6eRA0"
    //   )
    // .then((res) => {
    //   console.log(res);
    //   toast.success("Your message is sent !");
    //   setContactModal(false);
    //   setContactModal2(false);
    // })
    // .catch((err) => {
    //   console.log(err);
    //   toast.error("Somthing went wrong !");
    // });
  };

  if (contactModal2) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div
            className={classes.closeButton}
            onClick={() => setContactModal2(false)}
          >
            <MdOutlineClose />
          </div>
          <div className={classes.contactContainer1}>
            <h3>{t("contact_us")}</h3>
            <Container fluid>
              <Row>
                <Col lg={4}>
                  <div className={classes.details}>
                    <div className={classes.iconContainer}>
                      <img src={icon2} alt="" />
                    </div>
                    <div className={classes.content}>
                      <p>
                        {t("contactus_head_office")}:{" "}
                        <span>{t("contactus_head_office_span")}</span>
                      </p>
                      <p>
                        {t("contactus_city")}،{" "}
                        <span>{t("contactus_street")} ،</span>
                      </p>
                      <p>
                        {t("contactus_build")}:{" "}
                        <span>{t("contactus_build_num")}</span>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className={classes.details}>
                    <div className={classes.iconContainer}>
                      <img src={icon3} alt="" />
                    </div>
                    <div className={classes.content}>
                      <h6>{t("Mobile")}</h6>
                      <p>
                        01222473333 <img src={whatsApp} alt="" />
                      </p>
                      <p>01113332226</p>
                      <p>01001456795</p>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className={classes.details}>
                    <div className={classes.iconContainer}>
                      <img src={icon1} alt="" />
                    </div>
                    <div className={classes.content}>
                      <button
                        className={classes.btnSend}
                        onClick={() => {
                          setContactModal2(false);
                          setContactModal(true);
                        }}
                      >
                        {t("Messageus")}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  if (contactModal) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div
            className={classes.closeButton}
            onClick={() => setContactModal(false)}
          >
            <MdOutlineClose />
          </div>
          <div className={classes.contactContainer}>
            <Container fluid>
              <Row>
                <Col lg={4}>
                  <div className={classes.iconContainer}>
                    <img
                      src={icon1}
                      alt=""
                      className={classes.imgMessageIcon}
                    />
                  </div>
                </Col>
                <Col lg={8}>
                  <form onSubmit={sendEmail}>
                    <div className={classes.formGroup}>
                      <label htmlFor="name">{t("name")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        required
                        name="name"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label htmlFor="mobile">{t("Mobile")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        required
                        name="mobile"
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label htmlFor="email">{t("email")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        required
                        name="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.formGroupMessage}>
                      <label htmlFor="message">{t("message")}</label>
                      <textarea
                        name="message"
                        rows="5"
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <div className={classes.btnContainer}>
                      <button className={classes.btnSend1} type="submit">
                        {t("send")}
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
};

export default ContactUs;
