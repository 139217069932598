import React, { useState } from "react";
import classes from "./aboutUs.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ServiceIMG from "../../assets/imgs/Service 1.JPG";
import ServiceIMG2 from "../../assets/imgs/Service 2.JPG";
import ServiceIMG99 from "../../assets/imgs/ser3.jpg";
import ServiceIMG3 from "../../assets/imgs/DSCN6709 (2).JPG";
import ServiceIMG4 from "../../assets/imgs/Why Do You Need Us 1.JPG";
import ServiceIMG5 from "../../assets/imgs/Why Do You Need Us 2.jpg";
import ServiceIMG6 from "../../assets/imgs/Why Do You Need Us 3.JPG";
import ServiceIMG7 from "../../assets/imgs/Why Ci Group 1.jpg";
import ServiceIMG8 from "../../assets/imgs/Why Ci Group 2.jpg";
import ServiceIMG9 from "../../assets/imgs/Why Ci Group 3.jpg";
import icon1 from "../../assets/imgs/Icons/Asset 14.png";
import icon2 from "../../assets/imgs/Icons/Asset 12.png";
import icon3 from "../../assets/imgs/Icons/Asset 13.png";
import whatsApp from "../../assets/imgs/Icons/Asset 6.png";

import toast from "react-hot-toast";
import emailjs from "emailjs-com";

import Form from "react-bootstrap/Form";

import cookies from "js-cookie";
const languages = [
  {
    code: "en",
    name: "English",
    Country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    Country_code: "sa",
    dir: "rtl",
  },
];

const AboutUs = () => {
  const currentLangsCode = cookies.get("i18next") || "en";
  const currentLangs = languages.find((l) => l.code === currentLangsCode);
  const [t, i18n] = useTranslation();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    if (!name || !Email || !message || mobile) {
      toast.error("Please Field All Inputs !");
      return;
    }

    emailjs
      .sendForm(
        "service_pmij3ea",
        "template_aoprdir",
        e.target,
        "i_aocxF5NNj_6eRA0"
      )
      .then((res) => {
        toast.success("Your message is sent !");
      })
      .catch((err) => {
        toast.error("Somthing went wrong !");
      });
  };

  return (
    <div className={classes.bg}>
      <header className={classes.header}>
        <div className={classes.aboutcontent}>
          <p>{t("CI_Group_Paragraph_1")}</p>
          <p>{t("CI_Group_Paragraph_2")}</p>
          <p>{t("CI_Group_Paragraph_3")}</p>
          <p>{t("CI_Group_Paragraph_4")}</p>
          {currentLangs.name !== "العربية" && (
            <p>{t("CI_Group_Paragraph_5")}</p>
          )}
        </div>
        <div className={classes.header2}>
          <Row className="flex-md-row flex-column-reverse">
            <Col lg={7}>
              <div className={classes.featuresContent}>
                <h3>{t("services_h3")}</h3>
                <p>{t("features_p_1")}</p>
                <p>{t("features_p_2")}</p>
                {currentLangs.name !== "العربية" && <p>{t("features_p_3")}</p>}
              </div>
            </Col>
            <Col lg={5}>
              <div className={classes.imgContainer}>
                <img src={ServiceIMG} alt="" />
              </div>
              <div className={classes.imgContainer2}>
                <Row>
                  <Col lg={6}>
                    <img src={ServiceIMG2} alt="" />
                  </Col>
                  <Col lg={6}>
                    <img src={ServiceIMG3} alt="" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        {/*  */}
        <div className={classes.headerTwo}>
          <Row className="flex-md-row flex-column-reverse">
            <Col lg={6}>
              <div className={classes.imgContainer2}>
                <Row>
                  <Col
                    lg={6}
                    className={`${
                      currentLangsCode === "ar"
                        ? classes.arImg1
                        : classes.servIMG1
                    }`}
                  >
                    <img src={ServiceIMG4} alt="" />
                  </Col>
                  <Col
                    lg={6}
                    className={`${
                      currentLangsCode === "ar"
                        ? classes.arImg2
                        : classes.servIMG2
                    }`}
                  >
                    <img src={ServiceIMG5} alt="" />
                  </Col>
                </Row>
              </div>
              <div className={classes.imgContainer2}>
                <Row>
                  <Col
                    lg={6}
                    className={`${
                      currentLangsCode === "ar"
                        ? classes.arImg3
                        : classes.servIMG3
                    }`}
                  >
                    <img src={ServiceIMG6} alt="" />
                  </Col>
                  <Col
                    lg={6}
                    className={`${
                      currentLangsCode === "ar"
                        ? classes.arImg4
                        : classes.servIMG4
                    }`}
                  >
                    <img src={ServiceIMG99} alt="" />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6}>
              <div className={classes.featuresContent2}>
                <h3>{t("services_h3_1")}</h3>
                <p>{t("services_p_1")}</p>
                <p>{t("services_p_2")}</p>
              </div>
            </Col>
          </Row>
        </div>
        {/* ************headerthree***************  */}
        <div className={classes.headerthree}>
          <Row className="flex-md-row flex-column-reverse">
            <Col lg={6}>
              <div className={classes.aboutContianer}>
                <h1>{t("about-us1")}</h1>
                <h4>{t("about-us2")}</h4>
                <ul className={classes.aboutUl}>
                  <li>{t("aboutLI_1")}</li>
                  <li>{t("aboutLI_2")}</li>
                  <li>{t("aboutLI_3")}</li>
                  <li>{t("aboutLI_4")}</li>
                  <li>{t("aboutLI_5")}</li>
                  <li>{t("aboutLI_6")}</li>
                </ul>
              </div>
            </Col>
            <Col lg={6}>
              <div className={classes.imgContainer3}>
                <Row>
                  <Col lg={6}>{/* <img src={ServiceIMG} alt="" /> */}</Col>
                  <Col lg={6}>
                    <img src={ServiceIMG7} alt="" />
                  </Col>
                </Row>
              </div>
              <div className={classes.imgContainer3}>
                <Row>
                  <Col lg={6}>
                    <img src={ServiceIMG8} alt="" />
                  </Col>
                  <Col lg={6}>
                    <img src={ServiceIMG9} alt="" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        {/* ***************Contaact US******************** */}
        <div className={classes.contactContainer}>
          <Container fluid>
            <div className={classes.contactUSContent}>
              <Row>
                <Col lg={7}>
                  <div className={classes.borderContainer}>
                    <Row>
                      <Col lg={5}>
                        <div className={classes.iconContainer}>
                          <img
                            src={icon1}
                            alt=""
                            className={classes.imgMessageIcon}
                          />
                        </div>
                      </Col>
                      <Col lg={7}>
                        {/* <form onSubmit={"sendEmail"}>
                          <div className={classes.formGroup}>
                            <label htmlFor="name">{t("name")}</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              required
                              name="name"
                              // onChange={(e) => {
                              //   setName(e.target.value);
                              // }}
                            />
                          </div>
                          <div className={classes.formGroup}>
                            <label htmlFor="mobile">{t("Mobile")}</label>
                            <input
                              type="text"
                              className="form-control"
                              id="mobile"
                              required
                            />
                          </div>
                          <div className={classes.formGroup}>
                            <label htmlFor="email">{t("email")}</label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              required
                              name="email"
                              // onChange={(e) => {
                              //   setEmail(e.target.value);
                              // }}
                            />
                          </div>
                          <div className={classes.formGroupMessage}>
                            <label htmlFor="message">{t("message")}</label>
                            <textarea
                              name="message"
                              rows="5"
                              // onChange={(e) => {
                              //   setMessage(e.target.value);
                              // }}
                            ></textarea>
                          </div>
                          <div className={classes.btnContainer}>
                            <button className={classes.btnSend1}>
                              {t("send")}
                            </button>
                          </div>
                        </form> */}
                        {/* ************************Form Bootstrap************************* */}
                        <Form onSubmit={sendEmail}>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalEmail"
                          >
                            <Form.Label column sm={4} className={classes.label}>
                              {t("name")}
                            </Form.Label>
                            <Col sm={{ span: 8 }}>
                              <Form.Control
                                type="text"
                                className={classes.inputControl}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalEmail"
                          >
                            <Form.Label column sm={4} className={classes.label}>
                              {currentLangs.code === "ar"
                                ? "موبايل"
                                : t("Mobile")}
                            </Form.Label>
                            <Col sm={{ span: 8 }}>
                              <Form.Control
                                type="number"
                                className={classes.inputControl}
                                onChange={(e) => setMobile(e.target.value)}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalEmail"
                          >
                            <Form.Label column sm={4} className={classes.label}>
                              {t("email")}
                            </Form.Label>
                            <Col sm={{ span: 8 }}>
                              <Form.Control
                                type="Email"
                                className={classes.inputControl}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalEmail"
                          >
                            <Form.Label column sm={4} className={classes.label}>
                              {t("message")}
                            </Form.Label>
                            <Col sm={{ span: 8 }}>
                              <textarea
                                name=""
                                id=""
                                className={classes.textarea}
                                onChange={(e) => setMessage(e.target.value)}
                              ></textarea>
                            </Col>
                          </Form.Group>
                          {/* <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 12 }}>
                              <Button type="submit">Sign in</Button>
                            </Col>
                          </Form.Group> */}
                          <div
                            className={`${
                              currentLangs.code === "en"
                                ? classes.btnContainer
                                : classes.btnContainerAR
                            }`}
                          >
                            <button className={classes.btnSend1}>
                              {t("send")}
                            </button>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={5}>
                  <div
                    className={`${
                      currentLangs.code === "ar"
                        ? classes.details1
                        : classes.details
                    }`}
                  >
                    <div className={classes.firstDetails}>
                      <div
                        className={`${
                          currentLangs.code === "en"
                            ? classes.iconContainer
                            : classes.iconContainerAR
                        }`}
                      >
                        <img src={icon2} alt="" />
                      </div>
                      <div className={classes.content}>
                        <p>
                          {t("contactus_head_office")}:{" "}
                          <span>{t("contactus_head_office_span")}</span>
                        </p>
                        <p>
                          {t("contactus_city")}،{" "}
                          <span>{t("contactus_street")} ،</span>
                        </p>
                        <p>
                          {t("contactus_build")}:{" "}
                          <span>{t("contactus_build_num")}</span>
                        </p>
                      </div>
                    </div>
                    <div className={classes.firstDetailsBackground}></div>
                    <div className={`${classes.firstDetails}`}>
                      <div
                        className={`${
                          currentLangs.code === "en"
                            ? classes.iconContainer
                            : classes.iconContainerAR
                        }`}
                      >
                        <img src={icon3} alt="" />
                      </div>
                      <div className={`${classes.content} ${classes.content2}`}>
                        <h6>{t("Mobile")}</h6>
                        <p>
                          01222473333 <img src={whatsApp} alt="" />
                        </p>
                        <p>01113332226</p>
                        <p>01001456795</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </header>
    </div>
  );
};

export default AboutUs;
