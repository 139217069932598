import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./speak.module.css";

const Speak = () => {
  const [t, i18n] = useTranslation();
  return (
    <div className={classes.speak}>
      <h1>{t("speak")}</h1>
    </div>
  );
};

export default Speak;
