import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import classes from "./navbar.module.css";
import { Link } from "react-router-dom";
import DentalLogo from "../../../assets/imgs/Ci Group Logo aqua png.png";
import { Element } from "react-scroll";
import "./nav.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

const languages = [
  {
    code: "en",
    name: "English",
    Country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    Country_code: "sa",
    dir: "rtl",
  },
];

const NavbarComponent = ({ setContactModal2 }) => {
  const currentLangsCode = cookies.get("i18next") || "en";
  const currentLangs = languages.find((l) => l.code === currentLangsCode);
  const [t, i18n] = useTranslation();

  const [navbar, setNavbar] = useState(window.scrollY);
  const breakpoint = 66;

  useEffect(() => {
    window.addEventListener("scroll", () => setNavbar(window.scrollY));
  }, [navbar]);

  useEffect(() => {
    document.body.dir = currentLangs.dir || "ltr";
    document.title = t("app-title");
  }, [currentLangs, t]);

  return (
    <Element name="NavbarComponent">
      <div className={classes.header}>
        <Navbar
          className={`${classes.navbar} ${
            navbar > breakpoint && classes.navBackground
          }`}
          variant="dark"
          fixed="top"
          expand="lg"
          collapseOnSelect
        >
          <Container fluid>
            <Navbar.Brand>
              <Link to="/">
                <img className={classes.logo} src={DentalLogo} alt="" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle className={classes.navbarToggle} />
            <Navbar.Collapse className={classes.navbarCollapse}>
              <Nav className={classes.nav}>
                <LinkContainer to="/">
                  <Nav.Link>{t("home")}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/gallery/1">
                  <Nav.Link>{t("gallary")}</Nav.Link>
                </LinkContainer>
                {/* <NavDropdown
                  title={t("products")}
                  id="basic-nav-dropdown"
                  className={`${classes.navDropDown} myNav`}
                >
                  <NavDropdown.Item
                    className={classes.navDropDownItem}
                    href="#"
                    target="_blank"
                  >
                    Ci_Group
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className={classes.navDropDownItem}
                    href="https://secrets.teqneia.org/"
                    target="_blank"
                  >
                    Secrets
                  </NavDropdown.Item>
                </NavDropdown> */}

                <LinkContainer to="/aboutus">
                  <Nav.Link>{t("about-us")}</Nav.Link>
                </LinkContainer>
                <button href="" onClick={() => setContactModal2(true)}>
                  {t("contact_us")}
                </button>
              </Nav>
              {i18next.language === "ar" && (
                <button
                  onClick={() => {
                    i18next.changeLanguage("en");
                  }}
                  className={classes.btnLang}
                >
                  <span
                    style={{ fontSize: "20px", border: "1px solid #fff" }}
                    class={`fi fi-gb`}
                  ></span>
                </button>
              )}
              {i18next.language === "en" && (
                <button
                  onClick={() => {
                    i18next.changeLanguage("ar");
                  }}
                  className={classes.btnLang}
                >
                  <span
                    style={{ fontSize: "20px", border: "1px solid #fff" }}
                    class={`fi fi-eg`}
                  ></span>
                </button>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </Element>
  );
};

export default NavbarComponent;
