import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../doors/doors.module.css";
import Kitchens1 from "../../assets/imgs/Kitchens/IMG_-1.png";
import Kitchens2 from "../../assets/imgs/Kitchens/IMG_-2.png";
import Kitchens3 from "../../assets/imgs/Kitchens/IMG_8378.png";
import Kitchens4 from "../../assets/imgs/Kitchens/IMG_8385.png";
// import Kitchens5 from "../../assets/imgs/Kitchens/IMG_8392.png";
import Kitchens6 from "../../assets/imgs/Kitchens/IMG_8395.png";
import Kitchens7 from "../../assets/imgs/Kitchens/IMG_8411.png";
import Kitchens8 from "../../assets/imgs/Kitchens/IMG_8430.png";
import Kitchens9 from "../../assets/imgs/Kitchens/IMG_8451.png";
import Kitchens10 from "../../assets/imgs/Kitchens/IMG_8485.png";
import Kitchens11 from "../../assets/imgs/Kitchens/IMG_8489.png";
import Kitchens12 from "../../assets/imgs/Kitchens/IMG_8491.png";
import Kitchens13 from "../../assets/imgs/Kitchens/IMG_20200518_130622.png";
// import Kitchens14 from "../../assets/imgs/Kitchens/IMG_20200518_130640.png";
import Kitchens15 from "../../assets/imgs/Kitchens/IMG_20200518_130647.png";
import Kitchens16 from "../../assets/imgs/Kitchens/IMG_20200518_130702.png";

const Kitchens = () => {
  const [editModal, setEditModal] = useState(false);
  const [currentImg, setCurrentImg] = useState("");
  return (
    <div className={classes.doors}>
      <Container fluid>
        <Row>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens1}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens1);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens2}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens2);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens3}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens3);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens4}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens4);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          {/* <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens5}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens5);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col> */}
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens6}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens6);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens7}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens7);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens8}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens8);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens9}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens9);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens10}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens10);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens11}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens11);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens12}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens12);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens13}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens13);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          {/* <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens14}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens14);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col> */}
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens15}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens15);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Kitchens16}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Kitchens16);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {editModal && (
        <div className={`${classes.wrapper} ${editModal && classes.activeIMg}`}>
          <div className={classes.container}>
            <img
              src={currentImg}
              alt=""
              onMouseMove={() => setEditModal(true)}
              onMouseLeave={() => setEditModal(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Kitchens;
