import React from "react";
import classes from "./clients.module.css";
import { useTranslation } from "react-i18next";
import img1 from "../../../assets/imgs/Home Page 4.png";
import img2 from "../../../assets/imgs/Home Page 4 Arabic.png";
import cookies from "js-cookie";

const languages = [
  {
    code: "en",
    name: "English",
    Country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    Country_code: "sa",
    dir: "rtl",
  },
];

const Clients = () => {
  const currentLangsCode = cookies.get("i18next") || "en";
  const currentLangs = languages.find((l) => l.code === currentLangsCode);
  const [t, i18n] = useTranslation();

  return (
    <div className={classes.clients}>
      {currentLangs.name === "العربية" ? (
        <img src={img2} alt="" />
      ) : (
        <img src={img1} alt="" />
      )}
    </div>
  );
};

export default Clients;
