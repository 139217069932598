import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./doors.module.css";
import door1 from "../../assets/imgs/Doors/IMG_20200518_131744.png";
import door2 from "../../assets/imgs/Doors/IMG_20200518_131508.png";
import door3 from "../../assets/imgs/Doors/IMG_20200518_132614.png";
import door4 from "../../assets/imgs/Doors/IMG_20200518_131835.png";
// import door5 from "../../assets/imgs/Doors/IMG-20200111-WA0041.png";
import door6 from "../../assets/imgs/Doors/IMG_20200518_132348.png";
import door7 from "../../assets/imgs/Doors/IMG_20200518_132453.png";
import door8 from "../../assets/imgs/Doors/IMG_20200518_131644.jpg";
import { useState } from "react";

const Doors = () => {
  const [editModal, setEditModal] = useState(false);
  const [currentImg, setCurrentImg] = useState("");

  return (
    <div className={classes.doors}>
      <Container fluid>
        <Row>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={door1}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(door1);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={door2}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(door2);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={door3}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(door3);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={door4}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(door4);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          {/* <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={door5}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(door5);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col> */}
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={door6}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(door6);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={door7}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(door7);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={door8}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(door8);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {editModal && (
        <div className={`${classes.wrapper} ${editModal && classes.activeIMg}`}>
          <div className={classes.container}>
            <img
              src={currentImg}
              alt=""
              onMouseMove={() => setEditModal(true)}
              onMouseLeave={() => setEditModal(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Doors;
