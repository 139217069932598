import React from "react";
import classes from "./header.module.css";
import header1 from "../../../assets/imgs/Service 1.JPG";
import header2 from "../../../assets/imgs/Home Page 2.JPG";
// import header3 from "../../../assets/imgs/Home Page 3.jpg";
import header3 from '../../../assets/imgs/DSCN6709 (2).JPG'
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import CILogo from "../../../assets/imgs/Ci Group Logo aqua png.png";
import cookies from "js-cookie";

const languages = [
  {
    code: "en",
    name: "English",
    Country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    Country_code: "sa",
    dir: "rtl",
  },
];

const Header = () => {
  const currentLangsCode = cookies.get("i18next") || "en";
  const currentLangs = languages.find((l) => l.code === currentLangsCode);

  const [t, i18n] = useTranslation();

  return (
    <div className={classes.bg}>
      <Container fluid>
        <Row>
          <Col lg={6} className={classes.col}>
            <div className={classes.imgContainer}>
              <img src={header1} alt="" />
            </div>
          </Col>
          {/* <Col lg={2} className={classes.col}>
            <div className={classes.imgContainer}>
              <img src={header2} alt="" />
            </div>
          </Col> */}
          <Col lg={6} className={classes.col}>
            <div className={classes.imgContainer}>
              <img src={header3} alt="" />
            </div>
          </Col>
        </Row>
        <div className={classes.headingTwoContainer}>
          <Row>
            <Col lg={5} className={classes.col}>
              <div
                className={`${
                  currentLangs.name === "العربية" && classes.headingLang
                } ${classes.imgContainer2}`}
              >
                <h1>{t("headingCI_1")}</h1>
              </div>
            </Col>
            <Col lg={2} className={classes.col}>
              <div className={classes.ciImgContainer}>
                <img className={classes.ciIMG} src={CILogo} alt="" />
              </div>
            </Col>
            <Col lg={5} className={classes.col2} >
            <div
                className={`${
                  currentLangs.name === "العربية" && classes.headingLang2
                } ${classes.imgContainer3}`}
              >
                <h1>{t("headingCI_2")}</h1>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Header;
