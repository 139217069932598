import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactUs from "./components/ContactUs/ContactUs";
import GallaryPage from "./pages/GallaryPage";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import { Toaster } from "react-hot-toast";

const App = () => {
  const [contactModal, setContactModal] = useState(false);
  const [contactModal2, setContactModal2] = useState(false);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={<HomePage setContactModal2={setContactModal2} />}
          />
          <Route
            path="/gallery"
            element={<GallaryPage setContactModal2={setContactModal2} />}
          />
          <Route
            path="/gallery/:id"
            element={<GallaryPage setContactModal2={setContactModal2} />}
          />
          <Route
            path="/aboutus"
            element={<AboutUsPage setContactModal2={setContactModal2} />}
          />
        </Routes>
        <ContactUs
          setContactModal2={setContactModal2}
          contactModal2={contactModal2}
          setContactModal={setContactModal}
          contactModal={contactModal}
        />
      </Router>
      <Toaster
        position="bottom-right"
        toastOptions={{
          style: {
            fontSize: "1rem",
          },
        }}
      />
    </>
  );
};

export default App;

/* 
#48ae9e
*/
