import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../doors/doors.module.css";
// import Interior1 from "../../assets/imgs/interior Design/IMG_20200518_131052.png";
import Interior2 from "../../assets/imgs/interior Design/IMG_20200518_131338.png";
import Interior3 from "../../assets/imgs/interior Design/IMG_20200518_131620.png";
import Interior4 from "../../assets/imgs/interior Design/IMG_20200518_131903.png";
// import Interior5 from "../../assets/imgs/interior Design/IMG-20191230-WA0036.png";
// import Interior6 from "../../assets/imgs/interior Design/IMG-20191230-WA0037.png";
// import Interior7 from "../../assets/imgs/interior Design/IMG-20191230-WA0046.png";
// import Interior8 from "../../assets/imgs/interior Design/IMG-20191230-WA0055.png";
import Interior9 from "../../assets/imgs/interior Design/IMG-20191230-WA0062.png";
// import Interior10 from "../../assets/imgs/interior Design/IMG-20200111-WA0045.png";
import Interior11 from "../../assets/imgs/interior Design/DSCN6709.JPG";
import Interior12 from "../../assets/imgs/interior Design/DSCN6721.JPG";

const Interior = () => {
  const [editModal, setEditModal] = useState(false);
  const [currentImg, setCurrentImg] = useState("");
  
  return (
    <div className={classes.doors}>
      <Container fluid>
        <Row>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior2}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior2);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior3}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior3);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>

          {/* <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior5}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior5);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col> */}
          {/* <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior6}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior6);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col> */}
          {/* <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior7}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior7);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col> */}
          {/* <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior8}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior8);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col> */}
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior9}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior9);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          {/* <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior10}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior10);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col> */}
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior11}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior11);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior4}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior4);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior12}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior12);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          {/* <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Interior1}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Interior1);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col> */}
        </Row>
      </Container>
      {editModal && (
        <div className={`${classes.wrapper} ${editModal && classes.activeIMg}`}>
          <div className={classes.container}>
            <img
              src={currentImg}
              alt=""
              onMouseMove={() => setEditModal(true)}
              onMouseLeave={() => setEditModal(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Interior;
