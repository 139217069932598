import React from "react";
import classes from "./gallery.module.css";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import ser3 from "../../../assets/imgs/ser3.jpg";
import DoorsIMG from "../../../assets/imgs/Service 2.JPG";
import ciIMG from "../../../assets/imgs/Why Ci Group 2.jpg";
import KitchensIMG from "../../../assets/imgs/Kitchens/IMG_-1.png";
import { Link } from "react-router-dom";

const Gallery = () => {
  const [t, i18n] = useTranslation();

  return (
    <div className={classes.galleryContainer}>
      <h2> {t("gallary")}</h2>

      <Container fluid>
        <div className={classes.allIMGS}>
          <Row>
            <Col lg={3}>
              <div className={classes.containerIMG}>
                <Link to="/gallery/1">
                  <img src={KitchensIMG} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.containerIMG}>
                <Link to="/gallery/2">
                  <img src={ser3} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.containerIMG}>
                <Link to="/gallery/3">
                  <img src={ciIMG} alt="" />
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className={classes.containerIMG}>
                <Link to="/gallery/4">
                  <img src={DoorsIMG} alt="" />
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default Gallery;
