import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import NavbarComponent from "../components/home/navbar/NavbarComponent";
import { Element } from "react-scroll";
import { useLocation } from "react-router-dom";
import AboutUs from "../components/AboutUs/AboutUs";
import Footer from "../components/Footer/Footer";

const AboutUsPage = ({ setContactModal2 }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <Element id="AboutUsPage">
      <NavbarComponent setContactModal2={setContactModal2} />
      <AboutUs />
      <Footer />
    </Element>
  );
};

export default AboutUsPage;
