import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../doors/doors.module.css";
import Dressing1 from "../../assets/imgs/Dressing Room/IMG_8289.png";
import Dressing2 from "../../assets/imgs/Dressing Room/IMG_8295.png";
import Dressing3 from "../../assets/imgs/Dressing Room/IMG_8307.png";
import Dressing4 from "../../assets/imgs/Dressing Room/IMG_8315.png";
import Dressing5 from "../../assets/imgs/Dressing Room/IMG_8318.png";
import Dressing6 from "../../assets/imgs/Dressing Room/IMG_8334.png";
import Dressing7 from "../../assets/imgs/Dressing Room/IMG_8337.png";
import Dressing8 from "../../assets/imgs/Dressing Room/IMG_8341.png";
import Dressing9 from "../../assets/imgs/Dressing Room/IMG_8349.png";
import Dressing10 from "../../assets/imgs/Dressing Room/IMG_8352.png";
import Dressing11 from "../../assets/imgs/Dressing Room/IMG_8361.png";
import Dressing12 from "../../assets/imgs/Dressing Room/IMG_8371.png";

const Dressing = () => {
  const [editModal, setEditModal] = useState(false);
  const [currentImg, setCurrentImg] = useState("");
  return (
    <div className={classes.doors}>
      <Container fluid>
        <Row>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing1}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing1);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing2}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing2);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing3}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing3);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing4}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing4);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing5}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing5);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing6}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing6);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing7}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing7);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing8}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing8);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing9}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing9);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing10}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing10);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing11}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing11);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className={classes.containerIMG}>
              <img
                src={Dressing12}
                alt=""
                onMouseMove={() => {
                  setEditModal(true);
                  setCurrentImg(Dressing12);
                }}
                onMouseLeave={() => setEditModal(false)}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {editModal && (
        <div className={`${classes.wrapper} ${editModal && classes.activeIMg}`}>
          <div className={classes.container}>
            <img
              src={currentImg}
              alt=""
              onMouseMove={() => setEditModal(true)}
              onMouseLeave={() => setEditModal(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dressing;
